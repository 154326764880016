import { WorkShiftCloudFunction } from '../../infrastructure/firebase/cloudFunction/workShiftCloudFunction';

export class WorkShiftRepository {
  static create(workShift, emptySeat) {
    return WorkShiftCloudFunction.create(workShift, emptySeat);
  }

  // static get(uid) {
  //   return WorkShiftCloudFunction.get(uid);
  // }

  static delete(workShift) {
    return WorkShiftCloudFunction.delete(workShift);
  }

  // static update(uid, param) {
  //   return WorkShiftCloudFunction.update(uid, param);
  // }
}
