import firebae from '../config';

export class WorkShiftCloudFunction {
  static create(workShift, emptySeat) {
    const data = { workShift, emptySeat };
    return firebae.callCloudFunction('createWorkShift', data);
  }

  static delete(workShift) {
    const data = { workShift };
    return firebae.callCloudFunction('deleteWorkShift', data);
  }
  // static get(uid) {
  //   const data = { uid };
  //   return firebae.callCloudFunction('getFeePayStatus', data);
  // }
}
