export class Shop {
  #id;
  set id(value) {
    if (typeof value === 'undefined' || value === null) {
      this.#id = '';
    } else {
      this.#id = value;
    }
  }
  get id() {
    return this.#id;
  }

  #name;
  set name(value) {
    if (!value) {
      throw new Error('"name" is a required field.');
    }
    this.#name = value;
  }
  get name() {
    return this.#name;
  }

  #openTime;
  set openTime(value) {
    if (!value) {
      throw new Error('"openTime" is a required field.');
    }
    const regex = /^([0-9]|1[0-9]|2[0-3])$/;
    if (!regex.test(value)) {
      throw new Error('"openTime" is a format error. must range from 0 to 23');
    }
    this.#openTime = value;
  }
  get openTime() {
    return this.#openTime;
  }

  #holidayOpenTime;
  set holidayOpenTime(value) {
    if (!value) {
      throw new Error('"holidayOpenTime" is a required field.');
    }
    const regex = /^([0-9]|1[0-9]|2[0-3])$/;
    if (!regex.test(value)) {
      throw new Error('"holidayOpenTime" is a format error. must range from 0 to 23');
    }
    this.#holidayOpenTime = value;
  }
  get holidayOpenTime() {
    return this.#holidayOpenTime;
  }

  #closeTime;
  set closeTime(value) {
    if (!value) {
      throw new Error('"closeTime" is a required field.');
    }
    const regex = /^([0-9]|1[0-9]|2[0-3])$/;
    if (!regex.test(value)) {
      throw new Error('"closeTime" is a format error. must range from 0 to 23');
    }
    if (this.#openTime >= value) {
      throw new Error('"closeTime" is must be more than "openTime"');
    }
    this.#closeTime = value;
  }
  get closeTime() {
    return this.#closeTime;
  }

  #holidayCloseTime;
  set holidayCloseTime(value) {
    if (!value) {
      throw new Error('"holidayCloseTime" is a required field.');
    }
    const regex = /^([0-9]|1[0-9]|2[0-3])$/;
    if (!regex.test(value)) {
      throw new Error('"holidayCloseTime" is a format error. must range from 0 to 23');
    }
    if (this.#holidayOpenTime >= value) {
      throw new Error('"holidayCloseTime" is must be more than "holidayOpenTime"');
    }
    this.#holidayCloseTime = value;
  }
  get holidayCloseTime() {
    return this.#holidayCloseTime;
  }

  #capacity;
  set capacity(value) {
    if (typeof value !== 'number') {
      throw new Error('"capacity" must be an number.');
    }
    this.#capacity = value;
  }
  get capacity() {
    return this.#capacity;
  }

  #trialMaxCount;
  set trialMaxCount(value) {
    if (typeof value !== 'number') {
      throw new Error('"trialMaxCount" must be an number.');
    }
    if (value > this.#capacity) {
      throw new Error('"trialMaxCount" must be less than "capacity"');
    }
    this.#trialMaxCount = value;
  }
  get trialMaxCount() {
    return this.#trialMaxCount;
  }

  #leftyPosition;
  set leftyPosition(value) {
    if (!Array.isArray(value)) {
      throw new Error('"leftyPosition" must be an Array.');
    }
    if (value.length > this.#capacity) {
      throw new Error('"leftyPosition.length" must be less than "capacity"');
    }
    this.#leftyPosition = value;
  }
  get leftyPosition() {
    return this.#leftyPosition;
  }

  #lessonToFree;
  set lessonToFree(value) {
    if (typeof value !== 'number') {
      throw new Error('"lessonToFree" must be an number.');
    }
    if (value > this.#capacity) {
      throw new Error('"lessonToFree" must be less than "capacity"');
    }
    this.#lessonToFree = value;
  }
  get lessonToFree() {
    return this.#lessonToFree;
  }

  constructor(shop) {
    this.id = shop.id;
    this.name = shop.name;
    this.openTime = shop.openTime;
    this.closeTime = shop.closeTime;
    this.holidayOpenTime = shop.holidayOpenTime;
    this.holidayCloseTime = shop.holidayCloseTime;
    this.capacity = shop.capacity;
    this.trialMaxCount = shop.trialMaxCount;
    this.leftyPosition = shop.leftyPosition;
    this.lessonToFree = shop.lessonToFree;
  }

  toObject() {
    return {
      id: this.id,
      name: this.name,
      openTime: this.openTime,
      closeTime: this.closeTime,
      holidayOpenTime: this.holidayOpenTime,
      holidayCloseTime: this.holidayCloseTime,
      capacity: this.capacity,
      trialMaxCount: this.trialMaxCount,
      leftyPosition: this.leftyPosition,
      lessonToFree: this.lessonToFree,
    };
  }
}
