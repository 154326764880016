export class Schedule {
  #date;
  set date(value) {
    if (!value) {
      throw new Error('"date" is a required field.');
    }
    const regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    if (!regex.test(value)) {
      throw new Error('"date" is a format error. "YYYYY-MM-DD" is the correct format.');
    }
    this.#date = value;
  }
  get date() {
    return this.#date;
  }

  #instructors;
  set instructors(value) {
    if (!Array.isArray(value)) {
      throw new Error('"instructors" must be an Array.');
    }
    if (value.length > 2) {
      throw new Error('up to 2 "instructors" are allowed.');
    }
    try {
      this.#instructors = value.map((instructor) => {
        const object = {
          uid: instructor.uid,
          name: instructor.name,
          nameKana: instructor.nameKana,
          shiftRange: instructor.shiftRange,
        };
        return object;
      });
    } catch {
      throw new Error('Invalid "instructor" instance.');
    }
    // TODO 同一のインストラクターはNG
    // 時間帯が重複しているのもNG
  }
  get instructors() {
    return this.#instructors;
  }

  #type;
  set type(value) {
    const types = [
      'レッスン',
      'フリーレンジ',
      '休館',
    ];
    if (!types.includes(value)) {
      throw new Error('"レッスン", Must be "フリーレンジ" or "休館".');
    }
    this.#type = value;
  }
  get type() {
    return this.#type;
  }

  constructor(schedule) {
    this.date = schedule.date;
    this.instructors = schedule.instructors;
    this.type = schedule.type;
  }

  toObject() {
    return {
      date: this.date,
      instructors: this.instructors,
      type: this.type,
    };
  }

  getInstructor(time) {
    return this.#instructors.find((instructor) => instructor.shiftRange.includes(time));
  }
}

export class WorkShift {
  #shopId
  set shopId(value) {
    if (!value) {
      throw new Error('"shopId" is a required field.');
    }
    this.#shopId = value;
  }
  get shopId() {
    return this.#shopId;
  }

  #month;
  set month(value) {
    if (!value) {
      throw new Error('"month" is a required field.');
    }
    const regex = /^([0-9]{4}-(0[1-9]|1[0-2]))$/;
    if (!regex.test(value)) {
      throw new Error('"month" is a format error. "YYYYY-MM" is the correct format.');
    }
    this.#month = value;
  }
  get month() {
    return this.#month;
  }

  #status;
  set status(value) {
    if (typeof value !== 'boolean') {
      throw new Error('"status" must be an boolean.');
    }
    this.#status = value;
  }
  get status() {
    return this.#status;
  }

  #schedule;
  set schedule(value) {
    this.#schedule = new Schedule(value).toObject();
  }
  get schedule() {
    return this.#schedule;
  }

  constructor(workShift) {
    this.shopId = workShift.shopId;
    this.month = workShift.month;
    this.status = workShift.status;
    this.schedule = workShift.schedule;
  }

  toObject() {
    return {
      shopId: this.shopId,
      month: this.month,
      status: this.status,
      schedule: this.schedule,
    };
  }

  // addSchedule(schedule) {
  //   const scheduleObject = new Schedule(schedule).toObject();
  //   this.#schedule.push(scheduleObject);
  // }
}
