import { intersection, difference } from 'lodash';
import moment from 'moment';
import { Shop } from './shop';
import { Schedule } from './workShift';

export class EmptySeatByTime {
  #date;
  set date(value) {
    if (!value) {
      throw new Error('"date" is a required field.');
    }
    const regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    if (!regex.test(value)) {
      throw new Error('"date" is a format error. "YYYYY-MM-DD" is the correct format.');
    }
    this.#date = value;
  }
  get date() {
    return this.#date;
  }

  #time;
  set time(value) {
    if (!value) {
      throw new Error('"time" is a required field.');
    }
    const regex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
    if (!regex.test(value)) {
      throw new Error('"time" is a format error. "HH:mm" is the correct format.');
    }
    this.#time = value;
  }
  get time() {
    return this.#time;
  }

  #capacity;
  set capacity(value) {
    if (typeof value !== 'number') {
      throw new Error('"capacity" must be an number.');
    }
    this.#capacity = value;
  }
  get capacity() {
    return this.#capacity;
  }

  #emptyCategory;
  set emptyCategory(value) {
    if (!Array.isArray(value)) {
      throw new Error('"emptyCategory" must be an Array.');
    }
    if (value.length > this.#capacity) {
      throw new Error('"emptyCategory.length" must be less than "capacity"');
    }
    this.#emptyCategory = value;
  }
  get emptyCategory() {
    return this.#emptyCategory;
  }

  #instructor = {
    uid: '',
    name: 'なし',
    nameKana: '',
  };
  set instructor(value) {
    if (typeof value !== 'undefined' && value !== null) {
      try {
        this.#instructor.uid = value.uid;
      } catch {
        throw new Error('"instructor.uid" is a required field.');
      }
      try {
        this.#instructor.name = value.name;
      } catch {
        throw new Error('"instructor.name" is a required field.');
      }
      try {
        this.#instructor.nameKana = value.nameKana;
      } catch {
        throw new Error('"instructor.nameKana" is a required field.');
      }
    }
  }
  get instructor() {
    return this.#instructor;
  }

  #remainingLesson;
  set remainingLesson(value) {
    if (typeof value === 'undefined' || value === null) {
      throw new Error('"remainingLesson" is a required field.');
    }
    if (typeof value !== 'number') {
      throw new Error('"remainingLesson" must be an number.');
    }
    if (value > this.#capacity) {
      throw new Error('"remainingLesson" must be less than "capacity"');
    }
    if (!this.instructor) {
      this.#remainingLesson = 0;
    } else {
      this.#remainingLesson = value;
    }
  }
  get remainingLesson() {
    return this.#remainingLesson;
  }

  #trialMaxCount;
  set trialMaxCount(value) {
    if (typeof value !== 'number') {
      throw new Error('"trialMaxCount" must be an number.');
    }
    if (value > this.#capacity) {
      throw new Error('"trialMaxCount" must be less than "capacity"');
    }
    this.#trialMaxCount = value;
  }
  get trialMaxCount() {
    return this.#trialMaxCount;
  }

  #trialCount;
  set trialCount(value) {
    if (typeof value !== 'number') {
      throw new Error('"trialCount" must be an number.');
    }
    if (value > this.#trialMaxCount) {
      throw new Error('"trialCount" must be less than "trialMaxCount"');
    }
    this.#trialCount = value;
  }
  get trialCount() {
    return this.#trialCount;
  }

  #leftyPosition;
  set leftyPosition(value) {
    if (!Array.isArray(value)) {
      throw new Error('"leftyPosition" must be an Array.');
    }
    if (value.length > this.#capacity) {
      throw new Error('"leftyPosition.length" must be less than "capacity"');
    }
    this.#leftyPosition = value;
  }
  get leftyPosition() {
    return this.#leftyPosition;
  }

  #lessonToFree;
  set lessonToFree(value) {
    if (typeof value !== 'number') {
      throw new Error('"lessonToFree" must be an number.');
    }
    if (value > this.#capacity) {
      throw new Error('"lessonToFree" must be less than "capacity"');
    }
    this.#lessonToFree = value;
  }
  get lessonToFree() {
    return this.#lessonToFree;
  }

  constructor(byTime) {
    this.date = byTime.date;
    this.time = byTime.time;
    this.capacity = byTime.capacity;
    this.emptyCategory = byTime.emptyCategory;
    this.instructor = byTime.instructor;
    this.remainingLesson = byTime.remainingLesson;
    this.trialMaxCount = byTime.trialMaxCount;
    this.trialCount = byTime.trialCount;
    this.leftyPosition = byTime.leftyPosition;
    this.lessonToFree = byTime.lessonToFree;
  }

  toObject() {
    return {
      date: this.date,
      time: this.time,
      capacity: this.capacity,
      emptyCategory: this.emptyCategory,
      instructor: this.instructor,
      remainingLesson: this.remainingLesson,
      trialMaxCount: this.trialMaxCount,
      trialCount: this.trialCount,
      leftyPosition: this.leftyPosition,
      lessonToFree: this.lessonToFree,
    };
  }

  // capacity の値から emptyCategory を初期化
  resetEmptyCategory() {
    try {
      this.#emptyCategory = [...Array(this.#capacity).keys()].map(i => i + 1);
    } catch {
      throw new Error('Could not convert "capacity" to an "emptyCategory".');
    }
  }

  // レッスンの受け付け
  acceptLesson(category, isLefty) {
    if (typeof category !== 'number') {
      throw new Error('"category" must be an number.');
    }
    if (this.remainingLesson === 0) {
      throw new Error('"remainingLesson" is 0.');
    }
    if (!this.emptyCategory.includes(category)) {
      throw new Error('The specified "category" does not exist.');
    }
    if (isLefty && !this.leftyPosition.includes(category)) {
      throw new Error('There is no room in "leftyPosition".');
    }
    this.remainingLesson -= 1;
    this.emptyCategory = this.emptyCategory.filter((v) => v !== category);
  }
  // レッスンのキャンセル
  cancelLesson(category) {
    if (typeof category !== 'number') {
      throw new Error('"category" must be an number.');
    }
    if (this.emptyCategory.includes(category)) {
      throw new Error('This is already been cancelled.');
    }
    this.remainingLesson -= 1;
    this.emptyCategory.push(category).sort();
  }

  // フリーレンジ or メンテナンス の受け付け
  acceptFree(category, isLefty) {
    if (typeof category !== 'number') {
      throw new Error('"category" must be an number.');
    }
    if (!this.emptyCategory.includes(category)) {
      throw new Error('The specified "category" does not exist.');
    }
    if (isLefty && !this.leftyPosition.includes(category)) {
      throw new Error('There is no room in "leftyPosition".');
    }
    this.emptyCategory = this.emptyCategory.filter((v) => v !== category);
    // インストラクターあり（レッスン可）かつ 体験予約なしの場合
    if (this.instructor && this.trialCount === 0) {
      // レッスンの受付可能数を減らす
      this.remainingLesson -= 1;
    }
    // インストラクターあり（レッスン可）かつ 体験予約あり の場合、レッスンの受付可能数と空席数に差分がある可能性があるので
    // レッスンの受付可能数と空席数を比較し同じだった場合にのみ
    if (this.instructor && this.trialCount >= 1 && this.remainingLesson === this.emptyCategory.length) {
      // レッスンの受付可能数を減らす
      this.remainingLesson -= 1;
    }
  }
  // フリーレンジ or メンテナンスのキャンセル
  cancelFree(category) {
    if (typeof category !== 'number') {
      throw new Error('"category" must be an number.');
    }
    if (this.emptyCategory.includes(category)) {
      throw new Error('This is already been cancelled.');
    }
    this.emptyCategory.push(category).sort();
    // インストラクターあり（レッスン可）かつ 体験予約なしの場合
    if (this.instructor && this.trialCount === 0) {
      // レッスンの受付可能数を戻す
      this.remainingLesson += 1;
    }
    // インストラクターあり（レッスン可）かつ 体験予約あり の場合
    if (this.instructor && this.trialCount >= 1) {
      const orgRemainingLesson = this.capacity - this.lessonToFree - 1;
      // レッスンの受付可能数を空席数に合わせて減らしていた場合
      if (orgRemainingLesson > this.remainingLesson) {
        // レッスンの受付可能数を戻す
        this.remainingLesson += 1;
      }
    }
  }

  // 体験の受け付け
  acceptTrial(isLefty) {
    if (this.trialCount >= this.trialMaxCount) {
      throw new Error('"Trial" limit reached.');
    }
    if (!this.emptyCategory.length) {
      throw new Error('There is no room in "emptyCategory".');
    }
    if (isLefty) {
      const leftOnlyPosition = intersection(this.emptyCategory, this.leftyPosition);
      if (!leftOnlyPosition.length) {
        throw new Error('There is no room in "leftyPosition".');
      }
      this.emptyCategory = this.emptyCategory.filter((v) => v !== leftOnlyPosition[0]);
    } else {
      const rightOnlyPosition = difference(this.emptyCategory, this.leftyPosition);
      if (rightOnlyPosition.length) {
        this.emptyCategory = this.emptyCategory.filter((v) => v !== rightOnlyPosition[0]);
      } else {
        this.emptyCategory = this.emptyCategory.filter((v) => v !== this.emptyCategory[0]);
      }
    }
    if (this.trialCount === 0) {
      this.remainingLesson -= this.lessonToFree;
    }
    this.trialCount += 1;
    this.remainingLesson -= 1;
  }
  // 体験のキャンセル
  cancelTrial(category) {
    if (typeof category !== 'number') {
      throw new Error('"category" must be an number.');
    }
    if (this.trialCount === 0) {
      throw new Error('This is already been cancelled.');
    }
    this.emptyCategory.push(category).sort();
    this.trialCount -= 1;
    if (this.trialCount === 0) {
      this.remainingLesson += this.lessonToFree;
    }
    this.remainingLesson += 1;
  }
}

export class EmptySeat {
  #schedule;
  set schedule(value) {
    this.#schedule = new Schedule(value);
  }
  get schedule() {
    return this.#schedule;
  }

  #shop
  set shop(value) {
    this.#shop = new Shop(value);
  }
  get shop() {
    return this.#shop;
  }

  #byTime
  set byTime(value) {
    if (!Array.isArray(value)) {
      throw new Error('"byTime" must be an Array.');
    }
    this.#byTime = value.map((v) => new EmptySeatByTime(v));
  }
  get byTime() {
    return this.#byTime;
  }

  #isHoliday;
  set isHoliday(value) {
    if (typeof value !== 'boolean') {
      throw new Error('isHoliday must be an boolean.');
    }
    this.#isHoliday = value;
  }
  get isHoliday() {
    return this.#isHoliday;
  }

  constructor(emptySeat) {
    this.schedule = emptySeat.schedule;
    this.shop = emptySeat.shop;
    this.byTime = emptySeat.byTime;
    this.isHoliday = emptySeat.isHoliday;
  }

  initializeByTime() {
    this.#byTime = [];
    const emptyCategory = [...Array(this.shop.capacity).keys()].map(i => i + 1);
    const openTime = this.isHoliday ? this.shop.holidayOpenTime : this.shop.openTime;
    const closeTime = this.isHoliday ? this.shop.holidayCloseTime : this.shop.closeTime;
    for (let i = openTime; i <= closeTime; i += 1) {
      let remainingLesson = this.shop.capacity;
      const time = moment(this.schedule.date, 'YYYY-MM-DD').add(i, 'hours').format('HH:mm');
      let instructor = this.schedule.getInstructor(time);
      if (!instructor) {
        remainingLesson = 0;
        instructor = {
          uid: '',
          name: 'なし',
          nameKana: '',
        };
      }
      this.#byTime.push(new EmptySeatByTime({
        date: this.schedule.date,
        time: time,
        capacity: this.shop.capacity,
        emptyCategory: emptyCategory,
        instructor: instructor,
        remainingLesson: remainingLesson,
        trialMaxCount: this.shop.trialMaxCount,
        trialCount: 0,
        leftyPosition: this.shop.leftyPosition,
        lessonToFree: this.shop.lessonToFree,
      }));
    }
  }

  toObject() {
    this.#byTime = this.#byTime.map((v) => v.toObject());
    return {
      schedule: this.schedule.toObject(),
      shop: this.shop.toObject(),
      byTime: this.byTime,
      isHoliday: this.isHoliday,
    };
  }
}
