import { WorkShift } from '../domain/model/workShift';
import { EmptySeat } from '../domain/model/emptySeat';
import { WorkShiftRepository } from '../domain/service/workShiftRepository';

export class WorkShiftUseCase {
  static create(param) {
    const workShift = new WorkShift(param);
    const emptySeat = new EmptySeat(param);
    emptySeat.initializeByTime();
    return WorkShiftRepository.create(workShift.toObject(), emptySeat.toObject());
  }

  // static getByUserId(uid) {
  //   return WorkShiftRepository.get(uid);
  // }

  static delete(param) {
    // TODO：シフトの削除前に既に予約が入っていないか確認する必要がある。
    const workShift = new WorkShift(param);
    return WorkShiftRepository.delete(workShift.toObject());
  }

  // static update(uid, param) {
  //   return WorkShiftRepository.update(uid, param);
  // }
}
