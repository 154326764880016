<template>
  <div class='pa-6' style="height:95%">
    <h2>シフト管理</h2>
    <v-sheet
      tile
      height="60"
      class="d-flex"
    >
      <v-btn text class="ma-2" @click="prev()">
        <v-icon>mdi-chevron-left</v-icon>
        前月
      </v-btn>
      <v-spacer></v-spacer>
      <v-select
        v-model="selectShop"
        item-text="name"
        item-value="id"
        :items="shops"
        dense
        outlined
        hide-details
        label="店舗"
        class="ma-2"
        return-object
      ></v-select>
      <DatepickerMonth :selectMonth="selectMonth" @input="selectMonth = $event"></DatepickerMonth>
      <v-btn
        class="ma-2"
        @click="checkShift"
        outlined
        :disabled='shiftStatus'
        :depressed='true'
        color='orange darken-2'
      >
        {{ statusMsg }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text class="ma-2" @click="next()">
        翌月
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet id='calendar' style="height:90%">
      <thead v-if="loading">
        <tr class="v-data-table__progress">
          <th colspan="7" class="column">
            <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="v-progress-linear v-progress-linear--absolute v-progress-linear--visible theme--light" style="height: 4px;">
              <div class="v-progress-linear__background primary" style="opacity: 0.3; left: 0%; width: 100%;"></div>
              <div class="v-progress-linear__buffer"></div>
              <div class="v-progress-linear__indeterminate v-progress-linear__indeterminate--active">
                <div class="v-progress-linear__indeterminate long primary"></div>
                <div class="v-progress-linear__indeterminate short primary"></div>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <v-calendar
        v-else
        locale="ja-jp"
        ref="calendar"
        v-model="focus"
        :events="events"
        @click:date="viewDay"
        @click:day="viewDay"
        @click:event="viewDay"
        :day-format="timestamp => new Date(timestamp.date).getDate()"
        :month-format="timestamp => (new Date(timestamp.date).getMonth() + 1) + ' /'"
      ></v-calendar>
    </v-sheet>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-form ref='form' v-model='valid' lazy-validation>
        <v-card>
          <v-card-title>
            <span v-if='!isExistShift' class="headline">シフト登録</span>
            <span v-if='isExistShift' class="headline">シフト詳細</span>
          </v-card-title>
          <v-card-text class='pb-0'>
            <v-container>
              <v-row>
                <v-col cols="12" class='py-0'>
                  <p class='mb-0 text--primary'>
                    登録日
                    <span v-if='!isExistShift' class='required-label'>
                      必須
                    </span>
                  </p>
                  <p
                    v-if='!isExistShift'
                    class='mb-0 text--secondary v-messages'
                    style='line-height: 1.5;'
                  >
                  複数の日付が選択可能です。選択した日付に同一のシフト内容が登録されます。
                  </p>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="selectDates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="selectDates"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-show='!isExistShift'
                      v-model="selectDates"
                      locale="ja-jp"
                      :day-format="date => new Date(date).getDate()"
                      no-title
                      multiple
                      :allowed-dates="getAllowedDates"
                      :picker-date="selectDate"
                      :min="startOf"
                      :max="endOf"
                      :readonly='isExistShift'
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(selectDates)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols='12' class='py-0' v-if='!isExistShift'>
                  <p class='mb-0 text--primary'>
                    休日の営業時間で登録
                  </p>
                  <p
                    class='mb-0 text--secondary v-messages'
                    style='line-height: 1.5;'
                  >
                    土日祝日の場合はチェックをつけてください。
                  </p>
                  <v-checkbox
                    v-model='isHoliday'
                    label='休日の営業時間で登録する。'
                    class='text--secondary  v-messages'
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" class='py-0'>
                  <p class='mb-0 text--primary'>
                    シフト種別
                    <span v-if='!isExistShift' class='required-label'>
                      必須
                    </span>
                  </p>
                  <v-select
                    v-model='sendParam.shftType'
                    outlined
                    dense
                    :items='types'
                    item-text='type'
                    item-value='type'
                    required
                    :readonly='isExistShift'
                    :rules='[formRules.required]'
                  ></v-select>
                </v-col>
                <v-col cols="12" class='py-0'
                  v-for='(instructor, index) in sendParam.instructors'
                  :key='index'
                >
                  <v-row class='my-1' v-if='sendParam.shftType === typeLesson'>
                    <v-col cols="12" class='py-0'>
                      <p class='mb-0 text--primary'>
                        担当インストラクター
                        <span v-if='!isExistShift' class='required-label'>
                          必須
                        </span>
                        <v-btn x-small class='ml-2' color='blue darken-2' dark outlined @click='addInstructor'
                          v-if='sendParam.instructors.length < 2 && !isExistShift'
                        >
                          <v-icon class='pr-1'>mdi-account-plus-outline</v-icon>
                        </v-btn>
                        <v-btn x-small class='ml-2' color='red darken-2' dark outlined @click='delInstructor(index)'
                          v-else-if='!isExistShift'
                        >
                          <v-icon class='pr-1'>mdi-account-minus-outline</v-icon>
                        </v-btn>
                      </p>
                      <p
                        class='mb-0 text--secondary v-messages'
                        style='line-height: 1.5;'
                      >
                      2人まで選択できます。その場合「レッスン受付可能時間」を重複して登録することはできません。
                      </p>
                      <v-select
                        v-model='instructor.uid'
                        outlined
                        dense
                        :items="instructors"
                        item-text='name'
                        item-value='uid'
                        :readonly='isExistShift'
                        @change='setShitRenge(index)'
                        :rules='[formRules.required]'
                      ></v-select>
                    </v-col>
                    <v-col cols="12" class='py-0'>
                      <p class='mb-0 text--primary'>
                        レッスン受付可能時間
                        <span v-if='!isExistShift' class='required-label'>
                          必須
                        </span>
                      </p>
                      <p
                        class='mb-0 text--secondary v-messages'
                        style='line-height: 1.5;'
                      >
                      インストラクターがいない時間帯は自動的にフリーレンジになります。
                      </p>
                      <v-select
                        v-model='instructor.shiftRange'
                        outlined
                        dense
                        :items=shiftRangeItems
                        :menu-props='{ top: true, offsetY: true }'
                        chips
                        deletable-chips
                        multiple
                        :readonly='isExistShift'
                        :rules='[formRules.requiredArray]'
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" dark outlined @click="close" :disabled="submit">
              <v-icon class='pr-1'>mdi-close</v-icon>
              閉じる
            </v-btn>
            <v-btn v-if='!isExistShift' color="blue darken-2" dark outlined @click="createShift" :loading="submit">
              <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
              登録
            </v-btn>
            <v-btn v-if='isExistShift' color="red darken-2" dark outlined @click="delShift" :loading="submit">
              <v-icon class='pr-1'>mdi-trash-can-outline</v-icon>
              削除
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- confirm -->
    <v-dialog v-model='confirm' max-width='600px'>
      <v-card>
        <v-card-title>
          <span class="headline">シフトの確定</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-if='this.isCompleteShift'
            type="info"
            text
            dense
            border="left"
          >
            このシフトの内容で予約の受付を開始します。
          </v-alert>
          <v-alert
            v-if='!this.isCompleteShift'
            type="error"
            icon="mdi-alert-outline"
            text
            dense
            border="left"
          >
            登録されていない日付があるため確定できません。
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" dark outlined @click="confirm = false" :disabled="submit">
            <v-icon class='pr-1'>mdi-close</v-icon>
            キャンセル
          </v-btn>
          <v-btn v-if='this.isCompleteShift' color="orange darken-2" dark outlined @click="fixShift" :loadnig="submit">
            <v-icon class='pr-1'>mdi-content-save-outline</v-icon>
            確定する
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import lodash from 'lodash';
import util from '../util';
import config from '../mixins/config';
import DatepickerMonth from '../components/DatepickerMonth';
import { WorkShiftUseCase } from '../useCase/workShiftUseCase';

export default {
  components: {
    DatepickerMonth,
  },
  mixins: [config],
  data: () => ({
    shiftStatus: false,
    // 取得データ
    shops: [],
    instructorAll: [],
    // shiftRangeItems: [],
    instructors: [],
    events: [],
    // 表示制御
    focus: '',
    menu: false,
    isHoliday: false,
    dialog: false,
    confirm: false,
    selectShop: '',
    selectMonth: moment(new Date()).format('YYYY-MM'),
    isExistShift: false,
    isCompleteShift: false,
    loading: false,
    submit: false,
    // シフト登録時のパラメータ
    selectDate: '',
    selectDates: [],
    sendParam: {
      instructors: [
        { uid: '', name: '', nameKana: '', shiftRange: [] },
      ],
    },
    // 入力規則
    valid: true,
    formRules: {
      required: value => !!value || '必ず選択してください。',
      requiredArray: value => (value || '').length > 0 || '1つ以上選択してください。',
    },
  }),

  computed: {
    startOf() {
      return moment(this.selectMonth).startOf('month').format('YYYY-MM-DD');
    },
    endOf() {
      return moment(this.selectMonth).endOf('month').format('YYYY-MM-DD');
    },
    statusMsg() {
      return this.shiftStatus ? '確定済み' : 'シフトを確定し予約の受付を開始する';
    },
    shiftRangeItems() {
      return util.timeList(this.selectShop.openTime, this.selectShop.closeTime);
    },
    userInfo() {
      return this.$store.getters.user;
    },
    types() {
      return [
        { type: this.SHIFT_TYPE.lesson.text, color: this.SHIFT_TYPE.lesson.color },
        { type: this.SHIFT_TYPE.free.text, color: this.SHIFT_TYPE.free.color },
        { type: this.SHIFT_TYPE.close.text, color: this.SHIFT_TYPE.close.color },
      ];
    },
    typeLesson() {
      return this.SHIFT_TYPE.lesson.text;
    },
  },

  async mounted() {
    this.shops = await util.callCloudFunction('getShop');
    this.instructorAll = await util.callCloudFunction('getUser', { roll: 'instructor', status: 'all' });
    this.selectShop = this.shops[0];
    await this.reload();
  },

  watch: {
    async selectMonth() {
      this.focus = moment(this.selectMonth, 'YYYY-MM').format('YYYY-MM-DD');
      await this.reload();
    },
    async selectShop() {
      await this.reload();
      this.instructors = this.instructorAll.filter((v) => v.shops.includes(this.selectShop.id));
    },
    isHoliday() {
      if (!this.isExistShift) {
        this.sendParam.instructors = [
          { uid: '', name: '', nameKana: '', shiftRange: [] },
        ];
      }
    },
    // events() {
    //   this.$nextTick(() => this.adjustment());
    // },
  },

  methods: {
    addInstructor() {
      this.sendParam.instructors.push({ uid: '', name: '', nameKana: '' });
    },
    delInstructor(index) {
      this.sendParam.instructors.splice(index, 1);
    },
    checkShift() {
      const start = moment(this.startOf);
      const end = moment(this.endOf);
      const days = [];
      while (start.diff(end) <= 0) {
        days.push(start.format('YYYY-MM-DD'));
        start.add(1, 'day');
      }
      this.isCompleteShift = days.every(v => this.events.some((event) => event.start === v));
      this.confirm = true;
    },
    getAllowedDates(date) {
      if (!date) return true;
      return !this.events.some((v) => v.start === date);
    },
    async reload() {
      this.loading = true;
      const monthShift = await util.callCloudFunction('getWorkShift', { month: this.selectMonth, shopId: this.selectShop.id });
      if (monthShift) {
        this.shiftStatus = monthShift.status;
        this.events = monthShift.schedule.map((v) => {
          v.color = this.setColor(v.type);
          v.start = v.date;
          v.timed = false;
          if (v.type === 'レッスン') {
            const names = v.instructors.map((instructor) => instructor.name);
            v.name = names.join(', ');
          } else {
            v.name = v.type;
          }
          return v;
        });
      } else {
        this.shiftStatus = false;
        this.events = [];
      }
      this.loading = false;
    },
    // adjustment() {
    //   const div = document.getElementById('calendar');
    //   const eventElement = div.getElementsByClassName('v-event v-event-start v-event-end');
    //   if (!eventElement.length) {
    //     return;
    //   }
    //   for (const v of eventElement) {
    //     v.style.cssText = 'height: 50%; width: 95%; position: relative;';
    //     v.firstChild.style.cssText = 'position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); font-size: 15px; color: black; font-weight: bold;';
    //   }
    // },
    prev() {
      this.$refs.calendar.prev();
      this.selectMonth = moment(this.focus, 'YYYY-MM-DD').format('YYYY-MM');
    },
    next() {
      this.$refs.calendar.next();
      this.selectMonth = moment(this.focus, 'YYYY-MM-DD').format('YYYY-MM');
    },
    setShitRenge(index) {
      if (this.isHoliday) {
        this.sendParam.instructors[index].shiftRange = this.instructors.find(v => v.uid === this.sendParam.instructors[index].uid).holidayShift;
      } else {
        this.sendParam.instructors[index].shiftRange = this.instructors.find(v => v.uid === this.sendParam.instructors[index].uid).weekdayShift;
      }
    },
    setColor(type) {
      return this.types.find(v => v.type === type).color;
    },
    viewDay({ date, weekday }) {
      this.focus = date;
      this.dialog = true;
      this.isExistShift = false;
      this.selectDate = date;
      this.selectDates = [date];
      this.isHoliday = (weekday === 6 || weekday === 0);
      if (!this.events) return;
      const event = this.events.find(v => v.start === date);
      if (!event) return;
      this.isExistShift = true;
      this.sendParam.shftType = event.type;
      this.sendParam.instructors = event.instructors.map(v => {
        v.shiftRange = Object.values(v.shiftRange);
        return v;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
        this.sendParam = {
          instructors: [
            { uid: '', name: '', nameKana: '', shiftRange: [] },
          ],
        };
      });
    },
    setSnackbar(res) {
      this.snackbarParam = res;
      this.snackbar = true;
    },
    createSendParam() {
      let sendInstructors = [];
      let name = '';
      if (this.sendParam.instructors.length && this.sendParam.instructors[0].uid !== '') {
        sendInstructors = this.sendParam.instructors.map(instructor => {
          const target = this.instructors.find(v => v.uid === instructor.uid);
          const shiftRange = instructor.shiftRange;
          name = name === '' ? `${name}${target.name}` : `${name}, ${target.name}`;
          return { uid: target.uid, name: target.name, nameKana: target.nameKana, shiftRange: shiftRange };
        });
      }
      const param = {
        shop: this.selectShop,
        shopId: this.selectShop.id,
        status: this.shiftStatus,
        month: this.selectMonth,
        schedule: {
          type: this.sendParam.shftType,
          instructors: sendInstructors,
          name: name !== '' ? name : this.sendParam.shftType,
          timed: false,
        },
        byTime: [],
        isHoliday: this.isHoliday,
        // updateBy: this.userInfo.uid,
      };
      return param;
    },

    // シフト登録
    async createShift() {
      this.submit = true;
      if (!this.$refs.form.validate()) {
        this.$store.commit('snackbar/open', { text: '入力内容に誤りがあります。', color: 'error' });
        this.submit = false;
        return;
      }
      const param = this.createSendParam();
      const instructors = param.schedule.instructors;
      if (instructors.length >= 2) {
        if (instructors[0].uid === instructors[1].uid) {
          this.$store.commit('snackbar/open', { text: '同一のインストラクターを選択することはできません。', color: 'error' });
          this.submit = false;
          return;
        }
        const overlapping = lodash.intersection(Object.values(instructors[0].shiftRange), Object.values(instructors[1].shiftRange));
        if (overlapping.length) {
          const msgHint = overlapping.length > 6 ? '複数重複しています。' : `重複しています。(${overlapping.join(',')})`;
          this.$store.commit('snackbar/open', { text: `レッスン受付可能時間が${msgHint}`, color: 'error' });
          this.submit = false;
          return;
        }
      }
      // 選択日を1日づつ処理
      await Promise.all(this.selectDates.map(async (date) => {
        param.schedule.date = date;
        param.schedule.start = date;
        await WorkShiftUseCase.create(param).catch(() => {
          this.$store.commit('snackbar/open', { text: 'シフトの登録に失敗しました。', color: 'error' });
        });
      }));
      this.submit = false;
      this.reload();
      this.close();
    },

    // シフト削除
    async delShift() {
      this.submit = true;
      const param = this.createSendParam();
      param.schedule.date = this.selectDate;
      param.schedule.start = this.selectDate;
      await WorkShiftUseCase.delete(param).catch((error) => {
        this.$store.commit('snackbar/open', { text: error, color: 'error' });
      });
      this.reload();
      this.close();
      this.submit = false;
    },

    // シフト確定
    async fixShift() {
      const param = {
        shop: this.selectShop,
        month: this.selectMonth,
      };
      await util.callCloudFunction('decisionWorkShift', param).catch(() => {
        this.$store.commit('snackbar/open', { text: 'シフトの確定に失敗しました。', color: 'error' });
      });
      this.reload();
      this.confirm = false;
    },
  },
};
</script>
<style>
.v-messages__message {
  line-height: 13px !important;
}
.v-event.v-event-start.v-event-end {
  height: 35% !important;
  width: 90% !important;
  position: relative !important;
}
.v-event.v-event-start.v-event-end > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 14px;
  color: black;
  font-weight: bold;
}
</style>
